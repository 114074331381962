<template>
  <v-app>
    <!-- <v-app-bar v-if="!isIndex" app color="primary" dark flat>
      <v-spacer></v-spacer>
      <span class="johnMalam">John Malam</span>
      <v-spacer></v-spacer>
    </v-app-bar> -->

    <v-main>
      <Transition>
        <router-view />
      </Transition>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    isIndex() {
      return this.$route.name == 'home'
    },
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato');

.johnMalam {
  justify-content: center;
  align-self: center;
  font-family: 'Lato', sans-serif;
  font-size: 2em;
}
</style>